<template>
  <WsMain v-if="state">
    <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
    <div class="ws-content-section pb-80">
      <WsCard>
        <ValidationObserver
          ref="form"
          class="ws-content-section-observer"
        >
          <form
            ref="updateForm"
            @submit.prevent
          >
            <div class="ws-content-section pb-80">
              <WsRow class="ws-state-form">
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="_fields.name"
                    v-model="state.name"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="_fields.order_type"
                    v-model="state.order_type"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="_fields.is_active"
                    v-model="state.is_active"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    :stateData="state"
                    v-bind="_fields.duration"
                    :value="state.duration"
                    @input="$_onInput($event,'duration')"
                    @form-input="$_radioFormInput($event)"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="_fields.url"
                    :value="state.url"
                    @input="$_onInput($event,'url')"
                  ></WsState>
                </WsCol>
              </WsRow>
            </div>
          </form>
        </ValidationObserver>
      </WsCard>
    </div>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
import S_App_State from "@/__vue2stone_cms/service/app/state";
import model from "@/__vue2stone_cms/models/shop_product_cover_frame";
export default {
  data: () => ({
    loading: {
      submit: false,
    },
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    state: {},
    errorMessages: {},
    titleKey: "name",
  }),
  methods: {
    $_loadingStart() {
      this.loading.submit = true;
    },
    $_loadingStop() {
      this.loading.submit = false;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$_loadingStart();
      try {
        let postData = { ...this.state };
        postData.type = "discount_code";
        postData = S_App_State.getFormatedStates(this.fields, postData, true);
        if (postData.duration === "infinite") {
          postData.start_date = null;
          postData.end_date = null;
        }
        await this.$axios.patch(`/${this.modelName}/${this._id}`, postData);
        this.$_loadingStop();
        this.$router.push(`/${this.modelName}/${this._id}`);
        this.$store.dispatch("app/stopNeedSave");
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.$emit("error");
          this.errorMessages = error.response.data.message;
        }
        this.$_loadingStop();
      }
    },
    async $_fetchData() {
      this.$store.dispatch("app/startPageLoading");
      let getUrl = `/${this.modelName}/${this._id}`;
      try {
        const res = await this.$axios.get(getUrl);
        this.state = res.data.data;
        this.state.duration = "interval";
        this.state = {
          ...S_App_State.getValueFromFields(this.fields, this.state),
        };
        if (this.state.start_date) {
          this.state.duration = "interval";
        } else {
          this.state.duration = "infinite";
        }
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_radioFormInput($event) {
      this.state = { ...this.state, ...$event };
    },
    $_onInput($event, fieldKey) {
      this.state = { ...this.state, [fieldKey]: $event };
    },
  },
  computed: {
    _title() {
      if (!this.state) {
        return this.label;
      } else {
        return this.state[this.titleKey];
      }
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push({
        text: this.$t(this.label),
        to: `/${this.modelName}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this.$t(this._title),
        to: `/${this.modelName}/${this._id}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this.$t("edit"),
        disabled: true,
      });
      return _breadcrumbsItems;
    },
    _id() {
      return this.$route.params.id;
    },
    _fields() {
      let _fields = { ...this.fields };
      if (this.state.duration === "infinite") {
        _fields.duration.items[0].fields.start_date.rules = "";
        _fields.duration.items[0].fields.end_date.rules = "";
      } else {
        _fields.duration.items[0].fields.start_date.rules = "required";
        _fields.duration.items[0].fields.end_date.rules = "required";
      }
      return _fields;
    },
  },
  mounted() {
    this.$_fetchData();
  },
};
</script>